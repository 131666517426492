<template>
  <div class="archive-categories">
    <ExpansionPanel
      v-for="category in categories"
      :key="category.year"
      :expanded="category.year === selectedYear"
    >
      <template #title>
        <p class="mb-0 archive-category__title">
          {{ category.year }}
        </p>
      </template>

      <template #body>
        <div class="archive-category__months pl-8">
          <div
            v-for="month in category.months"
            :key="month.code"
            :class="[
              'archive-category__month',
              { active: month.code === selectedCategory },
            ]"
          >
            <NuxtLink :to="getLinkUrl(month.code)" @click.enter="closeDrawer()">
              <p class="mb-8">
                {{ month.label }}
              </p>
            </NuxtLink>
          </div>
        </div>
      </template>
    </ExpansionPanel>
  </div>
</template>

<script>
import ExpansionPanel from '~/components/ExpansionPanel'
import { PAGES } from '~/utils/constants'

export default {
  components: {
    ExpansionPanel,
  },
  props: {
    selectedCategory: {
      type: String,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
    updateDrawerState: {
      type: Function,
      required: true,
    },
  },
  computed: {
    selectedYear() {
      return parseInt(this.selectedCategory.split('-')[1])
    },
  },
  methods: {
    getLinkUrl(code) {
      return `${PAGES.archive}/${code}`
    },
    closeDrawer() {
      this.updateDrawerState({
        isDrawerShown: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.archive-category__title {
  font-size: $font-size-base;
}

.archive-category__month::first-letter {
  text-transform: uppercase;
}

.archive-category__month:last-of-type {
  p {
    margin-bottom: 0;
  }
}

.archive-category__month.active {
  a {
    font-weight: $font-weight-semibold;
  }
}

.archive-category__month {
  a {
    color: $black;
    text-decoration: none;

    &:hover,
    &:active {
      color: $black;
    }
  }
}

@include media-breakpoint-up(lg) {
  .archive-categories {
    max-width: 94px;
    margin-left: 24px;
    position: sticky;
    top: 8px;
  }
}
</style>
